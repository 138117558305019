* {
  margin: 0;
  box-sizing: border-box;
}
/* div{
 display: block;
 color: #fff;
} */
html {
  background: rgb(41, 44, 61);
  color: #fff;
}

/* .nav-ul {
padding: 5px;
margin: 0px;
background-color: rgb(15,17,25);
}
.nav-ul li{
display:  inline-block;
padding: 10px;
}
.nav-ul li a {
color: #fff;
text-decoration: none;
padding: 7px;
} */

.txt-up {
  text-transform: uppercase;
}

.card {
  background-color: #191c24 !important;
}

.bg_main {
  background-color: rgb(41, 44, 61);
}

.sidebar .nav .nav-item.active > .nav-link:before {
  background: rgb(251, 66, 48);
  /* background: #0090e7 */
}

.navbar .navbar-menu-wrapper .navbar-toggler {
  color: rgb(251, 66, 48);
}

/* .sidebar .nav .menu-items:nth-child(5n+1) .nav-link .menu-icon i{
  color: rgb(251,66,48);
}
.sidebar .nav .menu-items:nth-child(5n+2) .nav-link .menu-icon i{
  color: rgb(251,66,48);
}
.sidebar .nav .menu-items:nth-child(5n+3) .nav-link .menu-icon i{
  color: rgb(251,66,48);
}
.sidebar .nav .menu-items:nth-child(5n+4) .nav-link .menu-icon i{
  color: rgb(251,66,48);
} */

.content-wrapper {
  padding: 0.875rem 0.75rem;
}

.form-control:focus {
  color: #fff;
  border: 1px solid rgb(251, 66, 48);
}

.table {
  color: #fff !important;
}

.table tr {
  color: #fff !important;
}
table tbody .center {
  text-align: center;
}

.table thead th {
  color: #fff !important;
  background-color: #464646 !important;
  font-size: 16px;
}

.table-responsive::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  overflow: hidden;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 0px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  outline: 1px solid darkgrey;
}

.table-responsive::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  overflow: hidden;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

.page-link {
  position: relative !important;
  display: block !important;
  margin-top: 0.5rem !important;
  /* padding: 0.5rem 0.5rem !important; */
  /* line-height: 1 !important; */
  color: black !important;
  /* background-color: darkgrey !important; */
  background-color: rgb(228, 228, 228) !important;
}
.page-link:focus {
  box-shadow: none !important;
}
.page-link:hover {
  color: black !important;
}

.page-item.active .page-link {
  /* background-color: rgb(251,66,48); */
  background-color: white !important;
  color: black !important;
  border-color: white !important;
}
.page-item:nth-child(n) .page-link {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.fnt {
  font-size: 0.875rem !important;
  color: darkgrey !important;
}
/* Remove when applying we-styles */
/* .ln-ht{
  line-height: .25;
} */
.ibaFSn {
  background-color: rgb(33 37 41) !important;
}
.jQWkjX {
  background-color: rgb(70 70 70) !important;
}
.jPphKt {
  border: 1px solid #454d55 !important;
}

.bg_pageNum {
  background-color: #464646;
}

/* .table-responsive::-webkit-scrollbar {
  width: 1em;
} */

/* .table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.table-responsive::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: black;
  outline: 1px solid darkgrey;
} */

.appButton {
  /* position: relative; */
  font-weight: 600;
  border-radius: 3px;
  background-color: rgb(251, 66, 48);
  text-transform: uppercase;
  font-size: 12px;
  border: none !important;
  padding: 10px;
}
.nav-right {
  text-align: right;
}

.invalid-input {
  display: block;
  color: red;
  margin-top: -20px;
  margin-left: 20px;
}

.nav-tabs .nav-link {
  background: 0 0;
}

.text-secondary {
  color: #959b9d !important;
}

.search input:focus {
  background-color: #fff;

  box-shadow: none;
  border: 1.7px solid var(--primary);
  color: var(--primary);
  color: #000;
}

.search input {
  background-color: #fff;
  height: 55px;
  font-size: 14px;
  border-radius: 3px;
  text-indent: 30px;
  border: 1px solid var(--primary) 00;
}

.ql-editor {
  min-height: 200px !important;
  background-color: #fff;
  color: #000;
}

.btnDel {
  padding: 10px 15px 10px 15px;
  font-weight: 500;
}
.btnUpdate {
  padding: 10px 15px 10px 15px;
  font-weight: 500;
}

.modal .btnClose {
  color: #fff;
}

select.form-control {
  color: white !important;
}
