.event_section {
  margin: 20px auto 40px;
}

.page-event {
  background-color: var(--black);
  padding-bottom: 40px;
  font-family: "Varela Round", sans-serif;
}

.page-event .upcoming-sec {
  padding: 10px 0;
  border-bottom: 1.5px dashed var(--primary);
}

.page-event .upcoming-sec .heading {
  font-size: 30px;
  color: var(--white);
}

.page-event .upcoming-event-list {
  color: #ccc;
}

.page-event .upcoming-event-list .event-block {
  padding: 20px 2px 10px;
  border-bottom: 1px solid var(--white);
}

.page-event .upcoming-event-list .event-block .sec-1,
.page-event .upcoming-event-list .event-block .sec-2 {
  padding-bottom: 3vh;
}

.page-event .upcoming-event-list .event-block .sec-1 table .month {
  text-transform: uppercase;
  color: var(--primary);
  font-size: 30px;
}

.page-event .upcoming-event-list .event-block .sec-1 table .month-date-devider {
  width: 5vh;
  margin: 0;
  background-color: var(--primary);
  height: 5px;
}

.page-event .upcoming-event-list .event-block .sec-1 table .date {
  font-size: 60px;
  font-weight: bolder;
  line-height: normal;
  color: var(--white);
}

.page-event .upcoming-event-list .event-block .sec-1 table .title {
  padding-left: 20px;
  vertical-align: top;
  color: var(--white);
  font-size: 30px;
}

.page-event .upcoming-event-list .event-block .sec-2 img {
  width: 100%;
}

.page-event .upcoming-event-list .event-block .sec-3 .title {
  font-size: 30px;
  padding-bottom: 2vh;
  color: var(--white);
}

.page-event .upcoming-event-list .event-block .sec-3 .venue table td {
  vertical-align: top;
  color: var(--white);
}

.page-event .upcoming-event-list .event-block .sec-3 .venue table td i {
  color: var(--primary);
  padding-left: 3px;
  padding-right: 10px;
}

.page-event .venue {
  margin-bottom: 20px;
}

.page-event
  .upcoming-event-list
  .event-block
  .sec-3
  .venue
  table
  td
  .dim-color
  a {
  color: var(--white);
  opacity: 0.5;
}

.page-event .upcoming-event-list .event-block .sec-3 .time {
  padding-bottom: 3vh;
  color: var(--white);
}

.page-event .upcoming-event-list .event-block .sec-3 .time table td {
  vertical-align: top;
}

.page-event .upcoming-event-list .event-block .sec-3 .time table td i {
  color: var(--primary);
  padding-right: 10px;
}

.page-event .upcoming-event-list .event-block .sec-3 .time table td .dim-color {
  color: rgba(204, 204, 204, 0.3);
}

.page-event .upcoming-event-list .event-block .sec-3 .sort-story {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  color: var(--white);
  overflow: hidden;
  font-size: 1rem;
}
.page-event .upcoming-event-list .event-block .sec-3 .sort-story p {
  font-size: 1rem;
}
.page-event .upcoming-event-list .event-block .sec-3 .group-of-btn {
  padding-top: 3vh;
}

.page-event
  .upcoming-event-list
  .event-block
  .sec-3
  .group-of-btn
  .book-ticket {
  background-color: var(--primary);
  color: #fff;
  margin-bottom: 10px;
}

.page-event .page-heading {
  text-transform: uppercase;
  color: #fff;
  padding-bottom: 17px;
  word-break: break-all;
  padding: 10px;
}

.page-event .page-heading::first-letter {
  color: var(--primary);
}

.img-event {
  max-height: 250px;
  object-fit: cover;
  overflow: hidden;
}

@media (min-width: 992px) {
  .upcoming-event-list .event-block .sec-1 table .title {
    display: none;
  }
}

@media (max-width: 991px) {
  .page-event .cover .heading {
    font-size: 180px;
    padding-top: 7vh;
  }

  .upcoming-event-list .event-block .sec-3 .title {
    display: none;
  }
}

@media (max-width: 767px) {
  .page-event .cover .heading {
    font-size: 140px;
    padding-top: 11vh;
  }
}

@media (max-width: 575px) {
  .page-event .cover {
    height: 214px;
  }

  .page-event .cover .heading {
    font-size: 70px;
  }

  .page-event .upcoming-event-list .event-block .sec-1 table .month {
    font-size: 14px;
  }

  .page-event .upcoming-event-list .event-block .sec-1 table .date {
    font-size: 36px;
  }

  .page-event .upcoming-event-list .event-block .sec-1 table .title {
    padding-left: 20px;
    font-size: 20px;
    vertical-align: top;
  }
}
