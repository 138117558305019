@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

:root {
  --white: #efeeeb;
  --black: #292c3d;
  --primary: #fb4230;
  --secondary: #ff9800e6;
}

body {
  line-height: 1.5;
  font-weight: 400;
  overflow-x: hidden;
  background: var(--white) !important;
}

* {
  margin: 0;
  box-sizing: border-box;
}

:before,
:after {
  box-sizing: border-box;
}

.v-center {
  align-items: center;
  background: #0f1119;
  padding: 12px 0px;
  display: flex;
  justify-content: space-between;
}

/* ul {
	list-style: none;
	margin: 0 !important;
	padding: 0 !important;
} */

a,
a:hover,
a:focus,
a:active {
  text-decoration: none !important;
}

.fa-angle-down {
  font-size: 12px;
  margin-left: 4px;
}

.highlighed_title {
  background: -webkit-linear-gradient(
    134deg,
    var(--primary) 0%,
    var(--secondary) 100%
  );
  background: linear-gradient(134deg, var(--primary) 0%, var(--secondary) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
}

.background,
.footer_section {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: -1;
}

/* ============================|Dark-Light Mode CSS|=================================== */
.dark-mode-toggle {
  /* position: fixed; */
  right: -2px;
  top: 200px;
  z-index: 999;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 40px;
  border-radius: 3px;
  background: var(--primary);
  border: 0.2rem solid var(--primary);
}

.dark-mode-toggle {
  color: #fff;
  font-size: 18px;
}

.theme_icon_sun {
  animation: animName 4s linear infinite;
}

.theme_icon_moon {
  animation: 2s infinite beatHeart;
}

.dark-mode-toggle:focus {
  outline: none;
  border-color: var(--primary);
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes beatHeart {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.08);
  }

  40% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.08);
  }

  100% {
    transform: scale(1);
  }
}

/* ------------------|Website Setting|------------------ */
.website_setting_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 40px;
  border-radius: 3px;
  background: var(--primary);
  border: 0.2rem solid var(--primary);
  position: fixed;
  right: -2px;
  top: 245px;
  z-index: 999;
  color: #fff;
}

.theme_setting_box {
  position: fixed;
  top: 245px;
  right: -250px;
  /* height: 245px; */
  width: 240px;
  /* background: var(--black); */
  background: #6a708f;

  z-index: 998;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.light_dark_label {
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.light_dark_box,
.color_picker_box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  margin: 10px;
  padding: 5px;
  border-radius: 4px;
}

.theme_setting_box_header {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  align-items: center;
}

.theme_setting_box_label {
  font-size: 14px;
  color: #fff;
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.theme_setting_box_close {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.padding_box {
  padding: 8px;
}

[type="color"] {
  appearance: none;
  padding: 0;
  height: 35px;
  border: 2px solid #fff;
  width: 37px;
  border-radius: 3px;
}

[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

[type="color"]::-webkit-color-swatch {
  border: none;
}

.reset_theme {
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

/* ---------------------- */
.coming_soon_title {
  color: #fff;
  text-align: center;
  font-size: 18px;
  background: var(--primary);
  width: 200px;
  padding: 5px;
  margin: auto;
}

/*=============================|Navigation Bar|===================================*/
.header {
  display: block;
  width: 100%;
  /*	position: fixed;*/
  position: relative;
  background: #0f1119;
  z-index: 99;
}

.header .item-left {
  /* flex: 0 0 17%; */
  padding-right: 15px;
  border-right: 1px dotted var(--primary);
}

.header .logo a {
  font-size: 30px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}

.header .item-center {
  flex: 1 0 67%;
}

.header .item-right {
  /* flex: 0 0 17%; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sign_up_button {
  border: 1px solid var(--primary);
  padding: 2px 10px;
  border-radius: 3px;
  transition: 1s ease;
}

.sign_up_button:hover {
  background: var(--primary);
  color: #fff !important;
}

.header .item-right a {
  text-decoration: none;
  font-size: 15px;
  color: #fff;
  display: inline-block;
  margin-left: 25px;
  white-space: nowrap;
  transition: color 0.3s ease;
  text-transform: uppercase;
  font-weight: 500;
}

.header .menu > ul > li {
  display: inline-block;
  line-height: 50px;
  margin-left: 25px;
}

.header .menu > ul > li > a {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  position: relative;
  text-transform: uppercase;
  transition: color 0.3s ease;
  text-decoration: none;
}

.header .menu > ul > li.first_list {
  margin-left: 0px !important;
}

.header .menu .menu-main {
  padding-left: 20px !important;
}

.header .menu > ul > li .sub-menu {
  position: absolute;
  z-index: 500;
  background-color: #ffffff;
  box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
  padding: 20px 30px;
  transition: all 0.5s ease;
  margin-top: 25px;
  opacity: 0;
  visibility: hidden;
}

@media (min-width: 768px) {
  .header .menu > ul > li.menu-item-has-children:hover .sub-menu {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }
}

.header .menu > ul > li .sub-menu > ul > li {
  line-height: 1;
}

.header .menu > ul > li .sub-menu > ul > li > a {
  display: inline-block;
  padding: 10px 0;
  font-size: 15px;
  color: #555555;
  transition: color 0.3s ease;
  text-decoration: none;
  text-transform: capitalize;
}

.header .menu > ul > li .single-column-menu {
  min-width: 280px;
  max-width: 350px;
}

.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li {
  line-height: 1;
  display: block;
}

.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a {
  padding: 10px 0;
  display: inline-block;
  font-size: 15px;
  color: #555555;
  transition: color 0.3s ease;
}

.header .menu > ul > li .sub-menu.custom_mega_menu > .list-item > ul > li > a {
  font-size: 20px;
  display: block;
  font-weight: 500;
  color: #000000bf;
  padding-bottom: 2px;
}

.header .menu > ul > li .sub-menu.custom_mega_menu > .list-item > ul > li {
  margin-bottom: 10px;
}

.header
  .menu
  > ul
  > li
  .sub-menu.custom_mega_menu
  > .list-item
  > ul
  > li
  > small {
  font-size: 12px;
  color: #464646bd;
}

.header .menu > ul > li .sub-menu.mega-menu {
  left: 50%;
  transform: translateX(-50%);
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 {
  max-width: 1100px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item {
  flex: 0 0 25%;
  padding: 0 15px;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item .title {
  font-size: 16px;
  color: var(--primary);
  font-weight: 500;
  line-height: 1;
  padding: 10px 0;
}

.header
  .menu
  > ul
  > li
  .sub-menu.mega-menu-column-4
  > .list-item.text-center
  .title {
  text-align: center;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img {
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  margin-top: 10px;
  height: 300px;
  object-fit: cover;
}

.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a:hover,
.header .menu > ul > li .sub-menu > ul > li > a:hover,
.header .item-right a:hover,
.header .menu > ul > li:hover > a {
  color: var(--primary);
}

.header .menu > ul > li:hover > a > i {
  transform: rotate(180deg);
}

/* banner section */
.banner-section {
  /* background-image: url('../img/banner.jpg'); */
  background-size: cover;
  background-position: center;
  height: 700px;
  width: 100%;
  display: block;
}

.mobile-menu-head,
.mobile-menu-trigger {
  display: none;
}

@media (max-width: 875px) {
  .header .item-center {
    flex: 1 0 62%;
  }

  .header .item-right a {
    margin-left: 14px;
    font-size: 13px;
    font-weight: 500;
  }

  .header .menu > ul > li {
    margin-left: 20px;
  }

  .header .menu .menu-main {
    padding-left: 8px !important;
  }
}

/*responsive*/
@media (max-width: 767px) {
  .header .item-center {
    order: 3;
    flex: 0 0 100%;
  }

  .header .item-left,
  .header .item-right {
    flex: 0 0 auto;
    border: none;
  }

  .v-center {
    justify-content: space-between;
    display: block;
    /*new*/
  }

  /*new*/
  .header-item.item-right {
    position: absolute;
    right: 12px;
    top: 19px;
  }

  .header .mobile-menu-trigger {
    display: flex;
    height: 30px;
    width: 30px;
    margin-left: 15px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .header .mobile-menu-trigger span {
    display: block;
    height: 2px;
    background-color: #fff;
    width: 24px;
    position: relative;
  }

  .header .mobile-menu-trigger span:before,
  .header .mobile-menu-trigger span:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

  .header .mobile-menu-trigger span:before {
    top: -6px;
  }

  .header .mobile-menu-trigger span:after {
    top: 6px;
  }

  .header .item-right {
    align-items: center;
    flex: 0 0 84%;
  }

  .header .menu {
    position: fixed;
    width: 320px;
    background-color: #ffffff;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    transform: translate(-100%);
    transition: all 0.5s ease;
    z-index: 1099;
  }

  .header .menu.active {
    transform: translate(0%);
  }

  .header .menu > ul > li {
    line-height: 1;
    margin: 0;
    display: block;
    transition: 1s;
  }

  .header .menu > ul > li > a {
    line-height: 50px;
    height: 50px;
    padding: 0 50px 0 15px;
    display: block;
    color: #000000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .header .menu > ul > li > a i {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 0;
    right: 0;
    text-align: center;
    line-height: 50px;
    transform: rotate(-90deg);
  }

  .header .menu .mobile-menu-head {
    display: flex;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 501;
    position: sticky;
    background-color: #ffffff;
    top: 0;
  }

  .header .menu .mobile-menu-head .go-back {
    height: 50px;
    width: 50px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 16px;
    display: none;
  }

  .header .menu .mobile-menu-head.active .go-back {
    display: block;
  }

  .header .menu .mobile-menu-head .current-menu-title {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
  }

  .header .menu .mobile-menu-head .mobile-menu-close {
    height: 50px;
    width: 50px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 25px;
  }

  .header .menu .menu-main {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px !important;
    margin-bottom: 0px !important;
  }

  .header .menu > ul > li .sub-menu.mega-menu,
  .header .menu > ul > li .sub-menu {
    visibility: visible;
    opacity: 1;
    position: absolute;
    box-shadow: none;
    margin: 0;
    padding: 15px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 65px;
    max-width: none;
    min-width: auto;
    display: none;
    transform: translateX(0%);
    overflow-y: auto;
  }

  .header .menu > ul > li .sub-menu.active {
    display: block;
  }

  @keyframes slideLeft {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes slideRight {
    0% {
      opacity: 1;
      transform: translateX(0%);
    }

    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  .header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img {
    margin-top: 0;
  }

  .header
    .menu
    > ul
    > li
    .sub-menu.mega-menu-column-4
    > .list-item.text-center
    .title {
    margin-bottom: 20px;
  }

  .header
    .menu
    > ul
    > li
    .sub-menu.mega-menu-column-4
    > .list-item.text-center:last-child
    .title {
    margin-bottom: 0px;
  }

  .header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item {
    flex: 0 0 100%;
    padding: 0px;
  }

  .header .menu > ul > li .sub-menu > ul > li > a,
  .header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a {
    display: block;
  }

  .header .menu > ul > li .sub-menu.mega-menu > .list-item > ul {
    margin-bottom: 15px;
  }

  .menu-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 400px) {
  .header .item-right {
    flex: 0 0 76%;
  }

  .header .item-right a {
    margin-left: 10px;
    font-size: 12px;
  }

  .header .mobile-menu-trigger {
    margin-left: 10px;
  }

  .header .menu {
    width: 260px;
  }
}

/*==========================|CUSTOM SCROLLBAR|============================= */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  overflow: hidden;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(78, 80, 96, 0.5);
  border-radius: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

/* =================================|Home page Banner|=================================== */
.banner .banner_row {
  /* height: 91.9vh; */
}

.banner {
  background-color: #292c3d !important;
}

.banner_content_box {
  /* height: 90vh; */
}

.banner_title {
  font-size: 56px;
  padding: 0px 20px 50px;
  font-weight: 800;
  font-family: Inter, sans-serif;
  line-height: 1.2;
  color: #fff;
}

.banner_title span {
  background: -webkit-linear-gradient(
    134deg,
    var(--primary) 0%,
    var(--secondary) 100%
  );
  background: linear-gradient(134deg, var(--primary) 0%, var(--secondary) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
}

.counter_box {
  /* height: 20vh; */
  background: rgb(4 7 28 / 48%);
  border: 1px solid #eeeeee29;
  margin-bottom: 50px;
}

@media (max-width: 1400px) {
  .banner_title {
    padding: 0px 0px 50px;
  }
}

@media (max-width: 1200px) {
  .banner_title {
    font-size: 50px;
  }
}

@media (max-width: 992px) {
  .banner_title {
    font-size: 35px;
    padding: 0px 0px 30px;
  }
}

@media (max-width: 768px) {
  .banner .banner_row {
    height: 100%;
  }

  .banner_box_1 {
    order: 2;
  }

  .banner_box_2 {
    order: 1;
  }

  .counter_box {
    order: 3;
  }

  .banner_title {
    text-align: center;
    padding: 20px 2px;
    font-size: 40px;
  }

  .banner_content_box {
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .banner_title {
    font-size: 30px;
  }

  .banner_box {
    height: 40vh;
  }
}
.tab-content {
  text-align: unset;
  border: 0px;
}

/* ======================|Counter |======================== */
.counter-container {
  text-align: center;
  margin: 25px auto;
}

.counter {
  font-size: 28px;
  color: rgb(249, 249, 249);
  font-weight: 700;
  line-height: 1.2;
  font-family: Inter, sans-serif;
}

.counter_title {
  white-space: nowrap;
  opacity: 1;
  font-weight: 500;
  color: var(--primary);
  font-size: 14px;
  letter-spacing: 0.7px;
  line-height: 1.4;
}

.counter_icon {
  margin-bottom: 10px;
  color: #fff;
}

/* ===============================|BANNER CIRCLE ANIMATION|========================== */
.banner_box {
  height: 60vh;
  background: radial-gradient(50% 50% at 50% 50%, #70e6ff29 0%, #0dcaf000 100%);
  /* background: #1E4141; */
}

.banner_box_2 .banner_box {
  line-height: 1.2rem;
  font-family: "dosislight";
  color: #fefefe;
  /* background-color: #11171d; */
  /* box-shadow: inset 0 8em 16em -8em black; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.floor:after {
  content: "";
  width: 50em;
  height: 25em;
  position: absolute;
  left: 50%;
  top: -80%;
  z-index: -1;
  transform: translate(-50%, -50%) rotateX(30deg) translateZ(-4em);
  background-image: radial-gradient(rgba(36, 49, 61, 0), #11171d 15em),
    radial-gradient(rgba(112, 230, 255, 0.2), rgba(0, 0, 0, 0)),
    linear-gradient(rgba(112, 230, 255, 0.1) 1px, transparent 1px),
    linear-gradient(
      90deg,
      transparent calc(100% - 1px),
      rgba(112, 230, 255, 0.1) calc(100% - 1px)
    ),
    linear-gradient(
      90deg,
      #11171d calc(100% - 1px),
      transparent calc(100% - 1px)
    ),
    linear-gradient(rgba(112, 230, 255, 0.5) 1px, transparent 1px);
  background-size: 100% 100%, 100% 100%, 6em 3em, 6em 3em, 6em 3em, 6em 3em;
  background-position: top center;
}

.proxz-nav {
  transform-style: preserve-3d;
}

.proxz-nav__system {
  perspective: 900px;
  position: relative;
  z-index: 1;
}

.proxz-nav__orbit,
.proxz-nav__orbit:before,
.proxz-nav__satellite {
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
}

.proxz-nav__orbit {
  z-index: 10;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  display: flex;
  align-items: center;
}

/* **************************************************
  step1 starts here
  ************************************************** */

.proxz-nav__orbit:nth-child(1) {
  width: 300px;
  height: 300px;
  z-index: 5;
  transform: translate(-50%, -50%) translateY(-110px) rotateX(65deg);
}

.proxz-nav__orbit:nth-child(1) .proxz-nav__label,
.proxz-nav__orbit:nth-child(1) .proxz-nav__satellite,
.proxz-nav__orbit:nth-child(1) .proxz-nav__satellite:before {
  -webkit-animation-duration: 38s;
  animation-duration: 38s;
}

.proxz-nav__orbit:nth-child(1) .proxz-nav__satellite:before {
  background-color: #70ffdb;
}

.proxz-nav__orbit:nth-child(1) .proxz-nav__label {
  color: #70ffdb;
}

.proxz-nav__orbit:nth-child(1) .proxz-nav__label:before {
  content: "01";
  color: rgba(254, 254, 254, 0.75);
  padding-right: 8px;
}

/* **************************************************
  step2 starts here
  ************************************************** */

.proxz-nav__orbit:nth-child(2) {
  width: 400px;
  height: 400px;
  z-index: 4;
  transform: translate(-50%, -50%) translateY(-80px) rotateX(65deg);
}

.proxz-nav__orbit:nth-child(2) .proxz-nav__label,
.proxz-nav__orbit:nth-child(2) .proxz-nav__satellite,
.proxz-nav__orbit:nth-child(2) .proxz-nav__satellite:before {
  -webkit-animation-duration: 36s;
  animation-duration: 36s;
}

.proxz-nav__orbit:nth-child(2) .proxz-nav__satellite:before {
  background-color: #70ffa0;
}

.proxz-nav__orbit:nth-child(2) .proxz-nav__label {
  color: #70ffa0;
}

.proxz-nav__orbit:nth-child(2) .proxz-nav__label:before {
  content: "02";
  color: rgba(254, 254, 254, 0.75);
  padding-right: 8px;
}

/* **************************************************
  step3 starts here
  ************************************************** */

.proxz-nav__orbit:nth-child(3) {
  width: 500px;
  height: 500px;
  z-index: 3;
  transform: translate(-50%, -50%) translateY(-50px) rotateX(65deg);
}

.proxz-nav__orbit:nth-child(3) .proxz-nav__label,
.proxz-nav__orbit:nth-child(3) .proxz-nav__satellite,
.proxz-nav__orbit:nth-child(3) .proxz-nav__satellite:before {
  -webkit-animation-duration: 34s;
  animation-duration: 34s;
}

.proxz-nav__orbit:nth-child(3) .proxz-nav__satellite:before {
  background-color: #7cff70;
}

.proxz-nav__orbit:nth-child(3) .proxz-nav__label {
  color: #7cff70;
}

.proxz-nav__orbit:nth-child(3) .proxz-nav__label:before {
  content: "03";
  color: rgba(254, 254, 254, 0.75);
  padding-right: 8px;
}

/* **************************************************
  step4 starts here
  ************************************************** */

.proxz-nav__orbit:nth-child(4) {
  width: 500px;
  height: 500px;
  z-index: 2;
  transform: translate(-50%, -50%) translateY(0px) rotateX(65deg);
}

.proxz-nav__orbit:nth-child(4) .proxz-nav__label,
.proxz-nav__orbit:nth-child(4) .proxz-nav__satellite,
.proxz-nav__orbit:nth-child(4) .proxz-nav__satellite:before {
  -webkit-animation-duration: 32s;
  animation-duration: 32s;
}

.proxz-nav__orbit:nth-child(4) .proxz-nav__satellite:before {
  background-color: #b8ff70;
}

.proxz-nav__orbit:nth-child(4) .proxz-nav__label {
  color: #b8ff70;
}

.proxz-nav__orbit:nth-child(4) .proxz-nav__label:before {
  content: "04";
  color: rgba(254, 254, 254, 0.75);
  padding-right: 8px;
}

/* **************************************************
  step5 starts here
  ************************************************** */

.proxz-nav__orbit:nth-child(5) {
  width: 400px;
  height: 400px;
  z-index: 1;
  transform: translate(-50%, -50%) translateY(50px) rotateX(65deg);
}

.proxz-nav__orbit:nth-child(5) .proxz-nav__label,
.proxz-nav__orbit:nth-child(5) .proxz-nav__satellite,
.proxz-nav__orbit:nth-child(5) .proxz-nav__satellite:before {
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
}

.proxz-nav__orbit:nth-child(5) .proxz-nav__satellite:before {
  background-color: #f3ff70;
}

.proxz-nav__orbit:nth-child(5) .proxz-nav__label {
  color: #f3ff70;
}

.proxz-nav__orbit:nth-child(5) .proxz-nav__label:before {
  content: "05";
  color: rgba(254, 254, 254, 0.75);
  padding-right: 8px;
}

/* **************************************************
  step6 starts here
  ************************************************** */

.proxz-nav__orbit:nth-child(6) {
  width: 300px;
  height: 300px;
  z-index: 0;
  transform: translate(-50%, -50%) translateY(100px) rotateX(65deg);
}

.proxz-nav__orbit:nth-child(6) .proxz-nav__label,
.proxz-nav__orbit:nth-child(6) .proxz-nav__satellite,
.proxz-nav__orbit:nth-child(6) .proxz-nav__satellite:before {
  -webkit-animation-duration: 28s;
  animation-duration: 28s;
}

.proxz-nav__orbit:nth-child(6) .proxz-nav__satellite:before {
  background-color: #ffcf70;
}

.proxz-nav__orbit:nth-child(6) .proxz-nav__label {
  color: #ffcf70;
}

.proxz-nav__orbit:nth-child(6) .proxz-nav__label:before {
  content: "06";
  color: rgba(254, 254, 254, 0.75);
  padding-right: 0.5em;
}

.proxz-nav__orbit:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 0;
  box-sizing: border-box;
  border: 1.2px dashed rgba(112, 230, 255, 0.5);
  transform: translate(-50%, -50%) rotate(0);
  animation: orbitRotation 40s linear infinite reverse;
}

.proxz-nav__orbit:hover {
  cursor: pointer;
}

.proxz-nav__orbit:hover:before {
  border-width: 2px;
  border-color: #70e6ff;
}

.proxz-nav__orbit:hover .proxz-nav__label {
  opacity: 1;
  text-shadow: 0 0 1em #70e6ff;
}

.proxz-nav__orbit:hover .proxz-nav__satellite:before {
  opacity: 1;
}

.proxz-nav__satellite {
  width: 100%;
  height: 100%;
  z-index: 1;
  transform: translate(-50%, -50%) rotate(0);
  -webkit-animation: orbitRotation 40s linear infinite;
  animation: orbitRotation 40s linear infinite;
  transform-style: preserve-3d;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.proxz-nav__satellite:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  position: absolute;
  right: 0;
  top: calc(50% - 41px);
  border-radius: 50%;
  transform: rotate(0) rotateX(-90deg);
  opacity: 0.5;
  animation: satelliteRotation 40s linear infinite reverse;
}

.proxz-nav__label {
  opacity: 0;
  padding: 0 8px 48px 0;
  pointer-events: none;
  text-align: left;
  text-transform: uppercase;
  transform-origin: 100% 50%;
  transform: rotate(0) rotateX(-90deg);
  transition: opacity 0.25s ease-in-out;
  animation: satelliteRotation 40s linear infinite reverse;
}

.proxz-nav__description {
  display: block;
  font-size: 12px;
  color: rgba(254, 254, 254, 0.75);
}

.hologram {
  perspective: 300px;
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 0;
  border-radius: 50%;
  background-image: radial-gradient(to top, #70e6ff, rgba(0, 177, 214, 0.3));
  background-repeat: no-repeat;
  box-shadow: 0 0.7em 0.25em -0.6em rgba(0, 0, 0, 0.5), 0 0.6em 1em -0.4em black,
    0 0 1.5em 0 #70e6ff, 0 0 6em 1em rgba(112, 230, 255, 0.5),
    0 0 3em 0.5em rgba(112, 230, 255, 0.5);
  transform-style: preserve-3d;
  pointer-events: none;
}

.hologram:after,
.hologram:before {
  content: "";
  position: absolute;
  pointer-events: none;
  border-radius: 0.4em;
}

.hologram:before {
  width: 100em;
  height: 100em;
  left: 50%;
  top: 0;
  z-index: 0;
  transform: translate(-50%, -50%) translateZ(-10em) rotateX(50deg) scale(0.75);
  /* background-image: radial-gradient(rgba(17, 23, 29, 0), #11171d 35%), radial-gradient(rgba(112, 230, 255, 0.2), rgba(0, 0, 0, 0)), linear-gradient(rgba(112, 230, 255, 0.1) 1px, transparent 1px), linear-gradient(90deg, transparent calc(100% - 1px), rgba(112, 230, 255, 0.1) calc(100% - 1px)), linear-gradient(90deg, #11171d calc(100% - 1px), transparent calc(100% - 1px)), linear-gradient(#70e6ff 1px, transparent 1px); */
  /* background-size: 100% 100%, 100% 100%, 6em 6em, 6em 6em, 6em 6em, 6em 6em; */
  /* background-position: top center; */
}

.hologram:after {
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 100vh;
  z-index: º;
  transform-origin: 50% 100%;
  transform: translate(-50%, 0) rotateX(-86.8deg);
  background-image: radial-gradient(
    to top,
    rgba(0, 0, 0, 0) 30%,
    rgba(112, 230, 255, 0.1) 60%,
    rgba(0, 0, 0, 0) 70%
  );
  opacity: 0.75;
  -webkit-animation: lightVibration 0.07s linear infinite;
  animation: lightVibration 0.07s linear infinite;
}

@-webkit-keyframes lightVibration {
  50% {
    opacity: 0.25;
    height: 100vh;
  }
}

@keyframes lightVibration {
  50% {
    opacity: 0.25;
    height: 100vh;
  }
}

@-webkit-keyframes orbitRotation {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes orbitRotation {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes satelliteRotation {
  100% {
    transform: rotate(360deg) rotateX(-90deg);
  }
}

@keyframes satelliteRotation {
  100% {
    transform: rotate(360deg) rotateX(-90deg);
  }
}

/* ===================================|ABOUT US SECTION CSS|============================================ */
.about-us-bg {
  background-position: right;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: cover !important;
  background: #292c3d;
  background-image: url("../img/bg_cover.jpg");
}

.section-header-v2 .section-title {
  text-transform: uppercase;
  font-size: 40px;
  font-weight: bolder;
  letter-spacing: 3px;
  color: white;
}

.section-header-v2 .section-subtitle {
  font-size: 18px;
  font-weight: 300;
  color: #d3d3d3;
}

.section-common-space {
  padding: 60px 0;
}

/*==================   ABOUT US  ==================*/
.tabbed-about-us .tab-pane {
  margin-bottom: 80px;
  display: none;
  border: none;
}

.tabbed-about-us .tab-content > .tab-pane {
  border: none;
}

.tabbed-about-us .tab-pane.active {
  display: block;
  -webkit-animation: fadeIn 0.5s ease-in-out 0.15s both;
  animation: fadeIn 0.5s ease-in-out 0.15s both;
}

.tabbed-about-us .img-wrapper {
  position: relative;
  min-height: 400px;
}

.tabbed-about-us .img-wrapper img {
  -webkit-box-shadow: 0px 40px 70px 0px rgba(0, 0, 0, 0.22);
  box-shadow: 0px 40px 70px 0px rgba(0, 0, 0, 0.22);
}

.tabbed-about-us .img-wrapper .img-one {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.tabbed-about-us .img-wrapper .img-two {
  position: absolute;
  top: 120px;
  left: 138px;
  display: none;
}

.tabbed-about-us .img-wrapper .img-three {
  position: absolute;
  top: 40px;
  left: 345px;
  display: none;
}

.tabbed-about-us .tab-pane.active .img-one {
  display: block;
  -webkit-animation: fadeIn 0.5s ease-in-out 0.15s both;
  animation: fadeIn 0.5s ease-in-out 0.15s both;
}

.tabbed-about-us .tab-pane.active .img-two {
  display: block;
  -webkit-animation: fadeIn 0.5s ease-in-out 0.5s both;
  animation: fadeIn 0.5s ease-in-out 0.5s both;
}

.tabbed-about-us .tab-pane.active .img-three {
  display: block;
  -webkit-animation: fadeIn 0.5s ease-in-out 0.85s both;
  animation: fadeIn 0.5s ease-in-out 0.85s both;
}

.tabbed-about-us .details-wrapper {
  padding-left: 30px;
}

.tabbed-about-us .details .title {
  text-transform: uppercase;
  color: #1c1c1c;
  margin-bottom: 50px;
}

.tabbed-about-us .details p {
  margin-bottom: 30px;
}

.tabbed-about-us .details p:last-child {
  margin-bottom: 0;
}

.pie-title-center {
  display: inline-block;
  position: relative;
  text-align: center;
}

.circle > span {
  display: block;
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -20px;
  line-height: 40px;
  color: #1c1c1c;
}

.circle > span:after {
  content: "%";
  margin-left: 0.1em;
}

.tabbed-about-us .work-progress {
  margin-top: 60px;
}

.tabbed-about-us .tabs-nav {
  padding: 30px 0 25px;
  border: none;
  text-align: center;
  border-radius: 4px;
}

.tabbed-about-us .tabs-nav li {
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  margin-right: 130px;
  position: relative;
}

.tabbed-about-us .tabs-nav li:last-child {
  margin-right: 0;
}

.tabbed-about-us .tabs-nav li * {
  color: #666666;
}

.tabbed-about-us .tabs-nav li span.icon {
  display: block;
  font-size: 30px;
  padding-bottom: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.tabbed-about-us .tabs-nav li:after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.tabbed-about-us .tabs-nav li.active:after {
  width: 100%;
}

/* ABOUT US VARAITONS **************************/
/*about-us-bg*/
.about-us-bg {
  background-position: right;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
}

.about-us-bg .section-header-v2 {
  margin-bottom: 90px;
}

/*tabbed-about-us-v2*/
.tabbed-about-us-v2.tabbed-about-us .tabs-nav {
  background-color: transparent;
  padding: 0;
}

.tabbed-about-us-v2.tabbed-about-us .tabs-nav li {
  display: table;
  margin-right: 30px;
  margin-bottom: 30px;
  background-color: #f5f5f5;
  width: 144px;
  height: 144px;
  float: left;
  border-radius: 2px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.tabbed-about-us-v2.tabbed-about-us .tabs-nav li > a {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  text-decoration: none;
  z-index: 10;
}

.tabbed-about-us-v2.tabbed-about-us .details-wrapper {
  padding-left: 0;
}

.tabbed-about-us-v2.tabbed-about-us .details p {
  font-size: 18px;
  font-weight: 300;
  color: #fff;
}

.tabbed-about-us-v2.tabbed-about-us .bgcolor-major-gradient-overlay {
  z-index: 1;
  opacity: 0;
}

.tabbed-about-us-v2.tabbed-about-us
  .tabs-nav
  li:hover
  .bgcolor-major-gradient-overlay,
.tabbed-about-us-v2.tabbed-about-us
  .tabs-nav
  li.active
  .bgcolor-major-gradient-overlay {
  opacity: 1;
}

.tabbed-about-us-v2.tabbed-about-us .tabs-nav li:hover *,
.tabbed-about-us-v2.tabbed-about-us .tabs-nav li.active * {
  color: #fff;
}

.tabbed-about-us-v2.tabbed-about-us .tabs-nav li:hover,
.tabbed-about-us-v2.tabbed-about-us .tabs-nav li.active {
  -webkit-box-shadow: 0px 25px 55px 0px rgba(0, 0, 0, 0.21),
    0px 16px 28px 0px rgba(0, 0, 0, 0.22);
  box-shadow: 0px 25px 55px 0px rgba(0, 0, 0, 0.21),
    0px 16px 28px 0px rgba(0, 0, 0, 0.22);
}

.tabbed-about-us-v2.tabbed-about-us .tabs-nav li.active:hover:after,
.tabbed-about-us-v2.tabbed-about-us .tabs-nav li.active:after {
  display: none;
}

.tabbed-about-us-v2.tabbed-about-us .work-progress {
  margin-top: 40px;
}

/*------ End of about us  ------*/

/*==================   PIE CHARTS  ==================*/
.work-progress .each-item {
  float: left;
  margin-right: 60px;
  text-align: center;
}

.work-progress .each-item:last-child {
  margin-right: 0;
}

.work-progress .each-item > div {
  display: inline-block;
  position: relative;
  text-align: center;
}

.work-progress .each-item .title {
  color: #ababab;
  text-transform: uppercase;
  margin-top: 10px;
}

/*------ End of pie charts  ------*/
.bgcolor-major-gradient-overlay,
.bgcolor-major-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.tabbed-about-us-v2.tabbed-about-us .work-progress .circle > span {
  color: #f3474b;
}

.tabbed-about-us .tabs-nav li:hover *,
.tabbed-about-us .tabs-nav li.active * {
  color: #f3474b;
}

.bgcolor-major-gradient-overlay {
  background-image: -moz-linear-gradient(
    292deg,
    var(--primary) 0%,
    var(--secondary) 100%
  );
  background-image: -webkit-linear-gradient(
    292deg,
    var(--primary) 0%,
    var(--secondary) 100%
  );
}

.input:after {
  background: #f3474b;
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .tabbed-about-us .details-wrapper {
    padding-left: 0;
  }

  .tabbed-about-us .details .title {
    margin-bottom: 20px;
  }

  .tabbed-about-us .work-progress {
    margin-top: 30px;
  }

  .tabbed-about-us .tabs-nav li {
    margin-right: 100px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  /*about us*/
  .tabbed-about-us .tabs-nav li {
    margin-right: 40px;
  }

  .tabbed-about-us-v2.tabbed-about-us .tabs-nav li {
    width: 120px;
    height: 120px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  /*tabbed-about-us*/
  .tabbed-about-us .details .title {
    margin-bottom: 20px;
  }

  .tabbed-about-us .details-wrapper {
    padding-left: 0;
  }

  .tabbed-about-us .work-progress {
    margin-top: 30px;
  }

  .work-progress .each-item {
    margin-right: 25px;
    margin-bottom: 15px;
  }

  .tabbed-about-us .tab-pane {
    margin-bottom: 30px;
  }

  .tabbed-about-us:not(.tabbed-about-us-v2) .tabs-nav {
    overflow: hidden;
    padding: 15px 15px 5px;
  }

  .tabbed-about-us-v2 .tabs-nav {
    float: left;
  }

  .tabbed-about-us .tabs-nav li {
    margin-right: 15px;
    margin-bottom: 10px;
  }

  .tabbed-about-us .tabs-nav li:after {
    display: none;
  }

  .tabbed-about-us .tabs-nav li a {
    font-size: 13px;
  }

  .tabbed-about-us .tabs-nav li span.icon {
    display: none;
  }

  .tabbed-about-us-v2.tabbed-about-us .tabs-nav li {
    height: initial;
    padding: 15px 10px;
  }

  .tabbed-about-us-v2.tabbed-about-us .details p {
    font-size: 14px;
    margin-bottom: 15px;
  }

  /*.pie*/
  .pie-value {
    font-size: 13px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 510px) {
  /*tabbed-about-us*/
  .tabbed-about-us .img-wrapper {
    min-height: 350px;
  }

  .tabbed-about-us .img-wrapper .img-one {
    width: 150px;
  }

  .tabbed-about-us .img-wrapper .img-two {
    width: 200px;
    top: 80px;
    left: 90px;
  }

  .tabbed-about-us .img-wrapper .img-three {
    width: 150px;
  }

  /* .about-us-bg {
		background: none !important;
	} */

  .tabbed-about-us-v2.tabbed-about-us .tabs-nav li {
    width: 43%;
  }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 360px) {
  .tabbed-about-us .img-wrapper .img-one {
    width: 130px;
  }

  .tabbed-about-us .img-wrapper .img-two {
    width: 160px;
    top: 70px;
    left: 30px;
  }

  .tabbed-about-us .img-wrapper .img-three {
    width: 130px;
    right: 0;
    left: initial;
  }
}

/* =================================================================== */
.tabbable-panel {
  border: 1px solid #eeeeee29;
  padding: 10px 0px;
}

.tabbable-line > .nav-tabs {
  border: none;
  margin: 0px;
  flex-wrap: nowrap;
  overflow-y: hidden;
}

.tabbable-line > .nav-tabs > li {
  margin-right: 2px;
  white-space: nowrap;
}

.tabbable-line > .nav-tabs > li > a {
  border: 0;
  padding: 5px 0px;
  margin-left: 20px;
  font-size: 15px;
  margin-right: 0;
  color: #fff !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent !important;
  border-bottom: 3.3px solid var(--primary) !important;
}

.tabbable-line > .nav-tabs > li > a > i {
  color: #a6a6a6;
}

.tabbable-line > .nav-tabs > li.open,
.tabbable-line > .nav-tabs > li:hover {
  /* border-bottom: 4px solid rgb(80,144,247); */
}

.tabbable-line > .nav-tabs > li.open > a,
.tabbable-line > .nav-tabs > li:hover > a {
  border: 0;
  background: none !important;
}

.tabbable-line > .nav-tabs > li.open > a > i,
.tabbable-line > .nav-tabs > li:hover > a > i {
  color: #a6a6a6;
}

.tabbable-line > .nav-tabs > li.open .dropdown-menu,
.tabbable-line > .nav-tabs > li:hover .dropdown-menu {
  margin-top: 0px;
}

.tabbable-line > .nav-tabs > li.active {
  border-bottom: 4px solid #32465b;
  position: relative;
}

.tabbable-line > .nav-tabs > li.active > a {
  border: 0;
  color: #333333;
}

.tabbable-line > .nav-tabs > li.active > a > i {
  color: #404040;
}

.tabbable-line > .tab-content {
  background-color: transparent;
  border: 0;
  /* border-top: 1px solid var(--primary) ; */
  padding: 25px 15px;
}

.tabbable-line > .tab-content > * {
  color: #ffffff;
}

.portlet .tabbable-line > .tab-content {
  padding-bottom: 0;
}

@media (max-width: 768px) {
  .tabbable-panel {
    border: none;
  }
}

@media (max-width: 500px) {
  .tab-content {
    padding: 25px 5px !important;
  }

  .search .search_btn {
    width: 75px !important;
    font-size: 14px !important;
  }
}

/* ================================================================= */
.search {
  width: 100%;
  position: relative;
  box-shadow: 0 0 40px rgb(51 51 51 / 10%);
}

.search .fa-search {
  position: absolute;
  top: 20px;
  left: 16px;
  color: var(--primary);
}

.search input {
  height: 55px;
  font-size: 14px;
  border-radius: 3px;
  text-indent: 30px;
  border: 1px solid var(--primary) 00;
}

.search input:focus {
  box-shadow: none;
  border: 1.7px solid var(--primary);
  color: var(--primary);
  color: #000;
}

.search .search_btn {
  position: absolute;
  top: 5px;
  right: 5px;
  font-weight: 600;
  height: 45px;
  border-radius: 3px;
  width: 110px;
  background: var(--primary);
  text-transform: uppercase;
  font-size: 15px;
  border: none !important;
}

:not(.btn-check) + .btn:active,
.search .search_btn:hover {
  color: #fff !important;
  background-color: var(--primary) !important;
}
/* ====================================|SIGNUP|====================================== */
.signup_btn {
  font-weight: 600;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  border: none !important;
  padding: 14px 25px 14px 25px;

  background: var(--primary);
}
.log_card .signup_btn {
  background: var(--primary);
}
.auth .sign-up .link {
  color: var(--primary) !important;
}
.auth .sign-up {
  color: var(--white);
}
/* ====================================|LOGIN|====================================== */

.login_btn {
  font-weight: 600;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  border: none !important;
  padding: 14px 25px 14px 25px;
  background: var(--primary);
}

:not(.btn-check) + .btn:active,
.login_btn:hover {
  color: #fff !important;
  background-color: var(--primary) !important;
}
.log_card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 50px;
  justify-content: center;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--black);
  background-clip: border-box;
  height: auto;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.5);
}
.half .content {
  width: 75%;
}
.half .loginSide {
  width: 25%;
}

.half,
.half .container > .row {
  min-height: 700px;
}
.bgImg .loginSideImg {
  background-image: url(../img/logside.png);
  width: 100%;
  min-height: 700px;
  object-fit: contain;
}
.half .loginSide .bg-text {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 12%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 25%;
  padding: 20px;
  text-align: center;
}
.bg-text h3 {
  background: linear-gradient(134deg, var(--primary) 0%, var(--secondary) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  font-weight: 700;
}
.log_card {
  max-width: 400px;
}
.log_card h3 {
  color: var(--white);
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.log_card .form-control {
  background-color: #efefef;
  color: #000;
}
.log_card label {
  color: var(--white);
}
.log_card .form-control {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.log_card .form-control:focus {
  border: 1px solid var(--primary);
}
.log_card .login_btn {
  background: var(--primary);
}
.auth .log-in .link {
  color: var(--primary) !important;
}
.auth .log-in {
  color: var(--white);
}
/* .login_card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  height: auto;
}
.login_card .loginSideImg {
  background: #292c3d;
  background-image: url(../img/logside.png);
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: 100%;
  height: 400px;
  object-fit: contain;
}
.login_card .bg-text h3 {
  background: linear-gradient(134deg, var(--primary) 0%, var(--secondary) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
}

 .login_card .bg-text {
  background-color: rgb(0, 0, 0); 
  background-color: rgba(0, 0, 0, 0.4); 
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
} 
.login_card .form-control {
  background-color: #fff;
  color: #000;
}
.login_card .form-control:focus {
  border: 1px solid var(--primary);
}
.login_card .login_btn {
  background: var(--primary);
} */

@media (max-width: 768px) {
  .card-title {
    font-size: 20px;
  }
  .login_card .loginSide {
    display: none;
  }
  .half .loginSide {
    width: 100%;
    min-height: 200px;
  }
  .bgImg .loginSideImg {
    width: 100%;
    min-height: 200px;
    object-fit: cover;
  }
  .half .content {
    width: 100%;
  }
  .half .loginSide .bg-text {
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
    font-weight: bold;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 50%;
    padding: 20px;
    text-align: center;
  }
}
/* ====================================|FOOTER|====================================== */
ul {
  margin: 0px;
  padding: 0px;
}

.footer-section {
  background: #0c0f15 !important;
  position: relative;
  position: relative;
  overflow: hidden;
  z-index: 9;
}

.footer-section:before {
  content: "";
  position: absolute;
  top: -146%;
  left: -18%;
  width: 44%;
  height: 257%;
  transform: rotate(54deg);
  background-color: rgb(23 26 45);
  -webkit-transform: rotate(54deg);
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -o-transform: rotate(54deg);
  z-index: -10;
}

.footer-section:after {
  position: absolute;
  content: "";
  background-color: rgb(23 26 45);
  top: -24%;
  right: 4%;
  width: 26%;
  height: 264%;
  transform: rotate(44deg);
  -webkit-transform: rotate(44deg);
  -moz-transform: rotate(44deg);
  -ms-transform: rotate(44deg);
  -o-transform: rotate(44deg);
  z-index: -10;
}

.footer-cta {
  border-bottom: 1px solid #a9a9a9;
  padding: 60px 5px 40px;
}

.single-cta i {
  color: var(--primary);
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #c9c9c9;
  font-size: 14px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #c9c9c9;
  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  position: relative;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  transition: 0.4s linear;
}

.footer-social-icon i:hover {
  transform: rotate(360deg);
}

/* -------------------------------------- */
.footer-social-icon a {
  color: #fff;
  display: inline-block;
  font-size: 16px;
  margin-right: 15px;
  position: relative;
  width: 40px;
  height: 40px;
  /* height: 24px; */
  background: var(--primary);
  border-radius: 50%;
}

/* 
.footer-social-icon a:before,
.footer-social-icon a:after {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
}

.footer-social-icon a:before {
	top: -10px;
    left: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 11px solid var(--primary);
}

.footer-social-icon a:after {
	bottom: -10px;
    left: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 11px solid var(--primary);
} */

/* ------------------------------------ */
.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: var(--primary);
}

.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.footer-widget ul li a:hover {
  color: var(--primary);
  margin-left: 3px;
}

.footer-widget ul li a {
  transition: 0.2s;
  font-size: 14px;
  color: #c9c9c9;
  text-transform: capitalize;
}

.footer-widget ul li i {
  color: var(--primary);
  font-size: 10px;
  margin-right: 5px;
}

.copyright-area {
  background: #0f1119;
  padding: 15px 0px;
}

.copyright-text p {
  margin: 0;
  font-size: 13px;
  color: #878787;
}

.copyright-text p a {
  color: var(--primary);
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: var(--primary);
}

.footer-menu li a {
  font-size: 13px;
  color: #878787;
}

@media (max-width: 768px) {
  .single-cta {
    margin-bottom: 15px;
  }

  .footer-cta {
    padding: 25px 5px 10px;
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  z-index: 99999;
}

.back-to-top svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 4px 0px;
  border-radius: 4px;
  background: var(--primary);
  color: #fff;
  transition: all 0.4s;
}

.back-to-top svg:hover {
  background: var(--primary);
  opacity: 0.9;
  color: #fff;
}

/* =================================|STAR ANIMATION|================================= */
.heading-page {
  background-color: #0c0f15;
  text-transform: uppercase;
  font-size: 43px;
  font-weight: bolder;
  letter-spacing: 3px;
  color: white;
}

/*
   * Selection color
   */
::-moz-selection {
  background-color: var(--primary);
  color: #fff;
}

::selection {
  background-color: var(--primary);
  color: #fff;
}

/*------------------------------------------------
	  Start Styling
  -------------------------------------------------*/
.site-wrapper {
  background: #0c0f15;
  padding: 60px 5px;
  position: relative;
  z-index: 1;
}

.page-title {
  margin-bottom: 75px;
}

.col_box {
  z-index: 2;
}

.page-title img {
  margin-bottom: 20px;
}

.page-title h2 {
  font-size: 68px;
  margin-bottom: 25px;
  position: relative;
  z-index: 0;
  font-weight: 900;
  text-transform: uppercase;
}

.page-title p {
  font-size: 14px;
  color: #ffffffc9;
}

.page-title .title-bg {
  color: rgba(30, 37, 48, 0.07);
  font-size: 158px;
  left: 0;
  letter-spacing: 10px;
  line-height: 0.7;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: -1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.section-title {
  margin-bottom: 20px;
}

.section-title h3 {
  display: inline-block;
  position: relative;
}

.section-title h3::before,
.section-title h3::after {
  content: "";
  height: 2px;
  position: absolute;
  bottom: 8px;
  left: -webkit-calc(100% + 14px);
  left: -moz-calc(100% + 14px);
  left: calc(100% + 14px);
}

.section-title h3::before {
  background-color: #1e2530;
  width: 96px;
  bottom: 14px;
}

.section-title h3::after {
  background-color: #fa6862;
  width: 73px;
}

.section-title.light h3 {
  color: #fff;
}

.section-title.light h3::before {
  background-color: #fff;
}

.page-nav {
  bottom: 40px;
  left: 0;
  position: absolute;
  right: 0;
}

.page-nav span {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 0.9;
  text-transform: uppercase;
}

/*------------------------------------------------
	  Home Page
  -------------------------------------------------*/

.hexagon-item:first-child {
  margin-left: 0;
}

.page-home {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: middle;
}

.page-home .overlay {
  background-color: rgba(14, 17, 24, 0.97);
}

/* End of container */
.hexagon-item {
  cursor: pointer;
  width: 200px;
  height: 173.20508px;
  float: left;
  margin-left: -29px;
  z-index: 0;
  position: relative;
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}

.hexagon-item:first-child {
  margin-left: 0;
}

.hexagon-item:hover {
  z-index: 1;
}

.hexagon-item:hover .hex-item:last-child {
  opacity: 1;
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}

.hexagon-item:hover .hex-item:first-child {
  opacity: 1;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.hexagon-item:hover .hex-item:first-child div:before,
.hexagon-item:hover .hex-item:first-child div:after {
  height: 5px;
}

.hexagon-item:hover .hex-item div::before,
.hexagon-item:hover .hex-item div::after {
  background-color: var(--primary);
}

.hexagon-item:hover .hex-content svg {
  -webkit-transform: scale(0.97);
  -moz-transform: scale(0.97);
  -ms-transform: scale(0.97);
  -o-transform: scale(0.97);
  transform: scale(0.97);
}

.page-home .hexagon-item:nth-last-child(1),
.page-home .hexagon-item:nth-last-child(2),
.page-home .hexagon-item:nth-last-child(3) {
  -webkit-transform: rotate(30deg) translate(87px, -80px);
  -moz-transform: rotate(30deg) translate(87px, -80px);
  -ms-transform: rotate(30deg) translate(87px, -80px);
  -o-transform: rotate(30deg) translate(87px, -80px);
  transform: rotate(30deg) translate(87px, -80px);
}

.hex-item {
  position: absolute;
  top: 0;
  left: 50px;
  width: 100px;
  height: 173.20508px;
}

.hex-item:first-child {
  z-index: 0;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hex-item:last-child {
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 1;
}

.hex-item div {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  width: 100px;
  height: 173.20508px;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
}

.hex-item div::before,
.hex-item div::after {
  background-color: #1e2530;
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.hex-item div:before {
  top: 0;
}

.hex-item div:after {
  bottom: 0;
}

.hex-item div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.hex-item div:nth-child(2) {
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  -o-transform: rotate(60deg);
  transform: rotate(60deg);
}

.hex-item div:nth-child(3) {
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  -o-transform: rotate(120deg);
  transform: rotate(120deg);
}

.hex-content {
  color: #fff;
  display: block;
  height: 180px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  transform: rotate(-30deg);
  width: 156px;
}

.hex-content .hex-content-inner {
  left: 50%;
  margin: -3px 0 0 2px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.hex-content .icon {
  display: block;
  font-size: 36px;
  line-height: 30px;
  margin-bottom: 11px;
}

.hex-content .title {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 24px;
  text-transform: uppercase;
}

.hex-content svg {
  left: -7px;
  position: absolute;
  top: -13px;
  transform: scale(0.87);
  z-index: -1;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.hex-content:hover {
  color: #fff;
}

.page-home .hexagon-item:nth-last-child(1),
.page-home .hexagon-item:nth-last-child(2),
.page-home .hexagon-item:nth-last-child(3) {
  -webkit-transform: rotate(30deg) translate(87px, -80px);
  -moz-transform: rotate(30deg) translate(87px, -80px);
  -ms-transform: rotate(30deg) translate(87px, -80px);
  -o-transform: rotate(30deg) translate(87px, -80px);
  transform: rotate(30deg) translate(87px, -80px);
}

/*------------------------------------------------
	  Welcome Page
  -------------------------------------------------*/
.author-image-large {
  position: absolute;
  right: 0;
  top: 0;
}

.author-image-large img {
  height: -webkit-calc(100vh - 4px);
  height: -moz-calc(100vh - 4px);
  height: calc(100vh - 4px);
}

@media (min-width: 1200px) {
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
}

@media (min-width: 1200px) {
  .col-lg-8 {
    width: 66.66666667%;
  }
}

.hexagon-item:first-child {
  margin-left: 0;
}

.pt-table.desktop-768 .pt-tablecell {
  padding-bottom: 110px;
  padding-top: 60px;
}

.hexagon-item:hover .icon i {
  color: var(--primary);
  transition: 0.6s;
}

.hexagon-item:hover .title {
  -webkit-animation: focus-in-contract 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: focus-in-contract 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/***************************/

@-webkit-keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .hexagon-item {
    float: none;
    margin: 0 auto 50px;
  }

  .hexagon-item:first-child {
    margin-left: auto;
  }

  .page-home .hexagon-item:nth-last-child(1),
  .page-home .hexagon-item:nth-last-child(2),
  .page-home .hexagon-item:nth-last-child(3) {
    -webkit-transform: rotate(30deg) translate(0px, 0px);
    -moz-transform: rotate(30deg) translate(0px, 0px);
    -ms-transform: rotate(30deg) translate(0px, 0px);
    -o-transform: rotate(30deg) translate(0px, 0px);
    transform: rotate(30deg) translate(0px, 0px);
  }
}

@media (max-width: 500px) {
  .hexagon-menu {
    overflow: hidden;
  }

  .hexagon-item:first-child {
    margin-top: 20px;
  }
}

/* ==================================|Page Not Found Page - 4040 |================================= */
.not_found_page {
  box-sizing: border-box;
  height: 100%;
  background-color: #000000;
  background-image: radial-gradient(#11581e, #041607),
    url("https://media.giphy.com/media/oEI9uBYSzLpBK/giphy.gif");
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Inconsolata", Helvetica, sans-serif;
  font-size: 1.5rem;
  color: rgba(128, 255, 128, 0.8);
  text-shadow: 0 0 1ex rgba(51, 255, 51, 1), 0 0 2px rgba(255, 255, 255, 0.8);
}

.noise {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-image: url("https://media.giphy.com/media/oEI9uBYSzLpBK/giphy.gif"); */
  background-repeat: no-repeat;
  background-size: cover;
  background: #292c3d;
  z-index: -1;
  opacity: 0.9;
}

.overlay {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background:
		repeating-linear-gradient(180deg,
			rgba(0, 0, 0, 0) 0,
			rgba(0, 0, 0, 0.3) 50%,
			rgba(0, 0, 0, 0) 100%); */
  background-size: auto 4px;
  z-index: 1;
}

.overlay::before {
  content: "";
  pointer-events: none;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    0deg,
    transparent 0%,
    rgba(32, 128, 32, 0.2) 2%,
    rgba(32, 128, 32, 0.8) 3%,
    rgba(32, 128, 32, 0.2) 3%,
    transparent 100%
  );
  background-repeat: no-repeat;
  animation: scan 7.5s linear 0s infinite;
}

@keyframes scan {
  0% {
    background-position: 0 -100vh;
  }

  35%,
  100% {
    background-position: 0 100vh;
  }
}

.terminal {
  box-sizing: inherit;
  position: absolute;
  height: 100%;
  width: 1000px;
  max-width: 100%;
  padding: 4rem;
  text-transform: uppercase;
}

.output {
  color: rgba(128, 255, 128, 0.8);
  text-shadow: 0 0 1px rgba(51, 255, 51, 0.4), 0 0 2px rgba(255, 255, 255, 0.8);
  text-transform: capitalize;
}

.output::before {
  content: "> ";
}

.output a {
  color: #fff;
  text-decoration: none;
}

.output a::before {
  content: "[";
}

.output a::after {
  content: "]";
}

.errorcode {
  color: white;
}

/* ==================================|News Page CSS|================================= */
.news_section {
  margin: 20px auto 40px;
}

.page_cover_box {
  height: 200px;
  background: #171a2d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news_box {
  background: #fff;
  border: 1px solid var(--black);
  border-radius: 0px;
  overflow: hidden;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.news_img_box {
  max-height: 410px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news_img_box .news_img {
  width: 100%;
  height: 100%;
}
.img-news-cover {
  max-height: 360px;
  object-fit: cover;
}

.news_publish_info_title {
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 6px;
  letter-spacing: 0.1em;
  color: #000;
}

.news_content_box {
  padding: 10px;
}

.news_title {
  font-size: 20px;
  line-height: 120%;
  text-transform: none;
  color: #000;
  max-width: 100%;
  margin-bottom: 12px;
  transition: 0.4s;
}

.news_title:hover {
  color: var(--primary);
  opacity: 0.8;
}

.latest_news_box {
  background: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

/* ==========================|Read more button css |====================== */
.button--text-thick {
  font-weight: 600;
}

.button--round-l {
  border-radius: 40px;
}

/* Common button styles */
.button--aylen {
  float: left;
  min-width: 150px;
  display: block;
  margin-bottom: 1em;
  margin-top: 10px;
  padding: 6px 10px;
  text-align: center;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.button--aylen:focus {
  outline: none;
}

.button.button--aylen {
  background: #292c3d;
  color: #ffffff;
  overflow: hidden;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.button--aylen.button--inverted {
  background: none;
  color: #fff;
}

.button--aylen::before,
.button--aylen::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 100%;
  left: 0;
  z-index: -1;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}

.button--aylen::before {
  background: var(--primary);
  opacity: 0.8;
}

.button--aylen::after {
  background: var(--primary);
}

.button--aylen:hover {
  color: #fff;
}

.button--aylen:hover::before,
.button--aylen:hover::after {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.button--aylen:hover::after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

.form-group .file-upload-info {
  background-color: #2a3038;
  color: white;
  cursor: pointer;
}
.log_card .form-group .file-upload-info {
  background-color: #efefef;
  cursor: pointer;
}

/* =========[User Profile]================ */
body {
  background: -webkit-linear-gradient(left, #3931af, #00c6ff);
}
.emp-profile {
  padding: 3%;
  margin-top: 3%;
  margin-bottom: 3%;
  background: var(--black);
}
.emp-profile .profile-img {
  text-align: center;
}
.emp-profile .profile-img img {
  width: 70%;
  height: 100%;
}
.emp-profile .profile-img .file {
  position: relative;
  overflow: hidden;
  margin-top: -20%;
  width: 70%;
  border: none;
  border-radius: 0;
  font-size: 15px;
  background: #212529b8;
}
.emp-profile .profile-img .file input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}
.emp-profile .profile-head h5 {
  color: var(--white);
}
/* .emp-profile .profile-head h6 {
  color: #0062cc;
} */
.emp-profile .profile-edit-btn {
  font-weight: 600;
  border-radius: 3px;
  background-color: rgb(251, 66, 48);
  text-transform: uppercase;
  font-size: 12px;
  border: none !important;
  padding: 10px;
  color: white;
  cursor: pointer;
}

.emp-profile .profile-head .nav-tabs {
  margin-bottom: 5%;
}
.emp-profile .profile-head .nav-tabs .nav-link {
  font-weight: 600;
  color: var(--white);
  border: none;
}
.emp-profile .profile-head .nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid var(--primary);
}
.emp-profile .profile-work {
  padding: 14%;
  margin-top: -15%;
}
.emp-profile .profile-work p {
  font-size: 12px;
  color: var(--white);
  font-weight: 600;
  margin-top: 10%;
}
.emp-profile .profile-work a {
  text-decoration: none;
  color: var(--primary);
  font-weight: 600;
  font-size: 14px;
}
.emp-profile .profile-work ul {
  list-style: none;
}
.emp-profile .profile-tab label {
  font-weight: 600;
  color: var(--white);
}
.emp-profile .profile-tab p {
  font-weight: 600;
  color: var(--white);
}

.captcha {
  color: #000;
  user-select: none;
  cursor: not-allowed;
}
/* .captchaBox {
  background: #efefef;
  border: 1px solid #efefef;
} */
.captchaBox .form-control {
  /* background: none; */
  /* border: 0px; */
  border: 1px solid #efefef;
  font-size: 16px;
}
.captchaBox .form-control::placeholder {
  text-align: center;
  opacity: 1;
  font-weight: 500;
  font-size: 20px;
}
.captchaBox label {
  line-height: 2.5;
}
@media only screen and (max-width: 210px) {
  .captchaBox .form-control::placeholder {
    font-size: 16px;
  }
}
@media only screen and (max-width: 575px) {
  .captchaBox label {
    line-height: 1;
  }
}
.captchaBox i {
  font-size: 14px;
  cursor: pointer;
  transition: 0.4s linear;
}
.captchaBox i:hover {
  transform: rotate(360deg);
}
.verifyBtn {
  font-weight: 500;
  color: white;
  border-radius: 3px;
  font-size: 12px;
  border: none !important;
  padding: 6px 14px;
  background: var(--primary);
}

.noPagebg {
  background: #292c3d;
}
.noPageLink a {
  color: #fb4230;
}
.noPageLink .link:hover,
.noPageLink a:hover {
  font-weight: 600;
  color: #fb4230;
}
