.blog_section {
  margin: 20px auto 40px;
  color: aliceblue;
}

.page_title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  text-transform: none;
  color: var(--black);
  transition: color 0.2s;
  margin-bottom: 20px;
}

.cover_blog_title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  text-transform: none;
  color: var(--black);
  transition: color 0.2s;

  max-width: 100%;
  margin-bottom: 12px;
  text-decoration: none;
  display: -webkit-box;
  height: 78px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.cover_blog_title:hover {
  color: var(--primary);
  opacity: 0.9;
}

.cover_blog_img {
  border: 2px solid transparent;
  border-radius: 5px;
  height: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-cover {
  height: 400px;
  object-fit: cover;
}
.img-side-cover {
  height: 116px;
  width: 100%;
  object-fit: cover;
}
.img-cat-cover {
  height: 225px;
  width: 100%;
  object-fit: cover;
  object-fit: cover;
}
.publish_info_title {
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 6px;
  letter-spacing: 0.1em;
  color: var(--black);
}

.publisher_name {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--black);
  opacity: 0.8;
  transition: color 0.2s;
}

.blog_cover_img {
  border: 2px solid transparent;
  border-radius: 5px;
  overflow: hidden;
  /* height: 150px; */
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog_title {
  font-weight: 600;
  font-size: 20px;
  color: var(--black);
  max-width: 100%;
  display: -webkit-box;
  height: 78px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  transition: color 0.2s;
  min-height: 51px;
  max-height: 51px;
}

.blog_title:hover {
  color: var(--primary);
  opacity: 0.9;
}

.blog_search_box {
  margin-top: 25px;
}

.blog_tabbable-panel {
  border: none !important;
  margin-top: 10px;
}

.blog_tabbable-panel .tabbable-line > .tab-content {
  padding: 0px !important;
  margin-top: 30px;
}

.blog_tabbable-panel .tabbable-line > .nav-tabs > li > a {
  margin-right: 20px;
  margin-left: 0px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: var(--black) !important;
}

.tab_blog_img {
  height: 100% !important;
  max-height: 225px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog_detail_cover_img img {
  width: 100%;
}

/* .premiumImage:hover {
  filter: blur(8px);
} */

.overlayPremium {
  position: absolute;
  opacity: 0;
  /* filter: blur(8px); */
  /* background-color: rgba(255, 255, 255, 0.144); */
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  height: 225px;
  width: 100%;
  transition: 0.3s ease-in-out;
}
.overlayPremiumTop {
  position: absolute;
  opacity: 0;
  /* filter: blur(8px); */
  /* background-color: rgba(255, 255, 255, 0.144); */
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  height: 400px;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.premiumImage:hover .overlayPremium {
  opacity: 1;
}
.premiumImage:hover .overlayPremiumTop {
  opacity: 1;
}

.premiumImage .icon {
  /* color: white; */
  color: var(--black);

  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

/* .fa-lock:hover {
  color: var(--primary);
} */

@media (max-width: 768px) {
  .blog_cover_img {
    height: 200px;
  }
  .img-cover {
    height: auto;
  }
  .img-cat-cover {
    height: auto;
  }
  .img-side-cover {
    height: auto;
    max-height: 200px;
  }
  .cover_blog_img {
    height: auto;
  }
  .overlayPremiumTop {
    height: 300px;
  }
}
@media (max-width: 500px) {
  .overlayPremiumTop {
    height: 240px;
  }
  .icon {
    font-size: 80px;
  }
}
@media (max-width: 370px) {
  .overlayPremiumTop {
    height: 200px;
  }
}
@media (max-width: 360px) {
  .overlayPremiumTop {
    height: 180px;
  }
}

.latest_news_title {
  color: var(--primary);
  font-size: 16px;
  font-weight: 500;
}

.new_publish_time {
  font-size: 13px;
  color: var(--primary);
}

.blog_description {
  margin: 40px auto;
}

/* .blog_description img {
  width: 100%;
  object-fit: contain;
} */

/* For Quil Text Editor */
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-justify {
  text-align: justify;
}
.ql-toolbar.ql-snow {
  background: #efefef !important;
}
.publisher_box {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 15px;
}

.publisher_img_box {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.blog_publisher_name,
.blog_publish_details {
  margin-right: 5px;
  color: var(--black);
}

.blog_sub_title {
  margin: 10px auto 20px;
  color: var(--black);
}

.blog_description > * {
  color: var(--black);
}

/* added */
.blog_description p {
  font-size: 16px;
}

.breadcrumb {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  background: var(--primary);
  display: inline-block;
  padding: 3px 13px;
}

.breadcrumb a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.blog_detail_title {
  margin-bottom: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  text-transform: none;
  color: var(--black);
  transition: color 0.2s;
}

@media (max-width: 768px) {
  .blog_detail_title {
    font-size: 20px;
  }

  .blog_sub_title {
    font-size: 18px;
  }
}
